/* General Article Page Layout */
.article-page {
  padding: 100px 20px 20px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.article-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  line-height: 1.6;
  font-size: 1rem;
  color: #333;
}

.article-content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #222;
  text-align: center;
}

.article-content img {
  max-width: 100%; /* Ensures images fit within the content */
  height: auto; /* Maintain aspect ratio */
  margin: 1rem 0;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
}

/* Close Button Styling */
.close-button {
  margin: 50px;
  background-color: #808080;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: rgb(159, 154, 154);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  /* Smaller close button */
  .close-button {
    font-size: 0.9rem; /* Slightly smaller text */
    padding: 0.4rem 0.8rem; /* Adjust padding */
    top: 15px; /* Adjust top margin */
    right: 15px; /* Adjust right margin */
    margin: 70px 20px;
  }
}

@media (max-width: 480px) {
  /* Even smaller close button for mobile devices */
  .close-button {
    font-size: 0.8rem; /* Even smaller text */
    padding: 0.3rem 0.6rem; /* Even smaller padding */
    top: 10px; /* Adjust top margin */
    right: 10px; /* Adjust right margin */
  }
}
