/* Banner Styling */
.banner {
  position: relative;
  width: 100%;
  height: 250px; /* Default height for larger screens */
  overflow: hidden;
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the entire banner area */
  z-index: 1;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Slightly darkened overlay */
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-text {
  color: #fff;
  text-align: center;
  z-index: 3; /* Ensures text is above the overlay and video */
}

.banner-text h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.banner-text p {
  font-size: 1.2rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner {
    height: 200px; /* Reduce height for smaller devices */
  }

  .banner-text h2 {
    font-size: 1.5rem;
  }

  .banner-text p {
    font-size: 1rem;
  }
}

/* Centered Card Grid */

.card-grid {
  display: flex;
  flex-direction: column; /* Stack cards in a single column for smaller screens */
  align-items: center; /* Center align cards horizontally */
  gap: 1rem; /* Space between cards */
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

/* Card Styling */
.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%; /* Full width for smaller screens */
  max-width: 30cm; /* Increase max width for larger screens */
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */

/* For Medium Screens (tablets, up to 768px) */
@media (min-width: 768px) {
  .card-grid {
    flex-direction: row; /* Display cards in a row for medium screens */
    flex-wrap: wrap; /* Wrap cards to the next row if needed */
    justify-content: center; /* Center align the cards */
  }

  .card {
    width: 100%; /* Allow cards to take the full width of the container */
    max-width: 700px; /* Adjust width for medium screens */
  }
}

/* For Large Screens (desktops, 1024px and above) */
@media (min-width: 1024px) {
  .card-grid {
    flex-direction: row; /* Maintain row layout for larger screens */
    justify-content: center; /* Center align the cards */
  }

  .card {
    max-width: 900px; /* Cards max width to double the original size */
  }
}
