/* App.css */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7ff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.app-container {
  max-width: 1200px;
  padding: 20px;
}

/* Card Styling */
.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  text-align: center;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.close-button {
  background-color: transparent;
  color: #333;
  font-size: 18px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.article-page,
.topic-page,
.home-page {
  padding: 20px;
}

/* Pastel Colors for Buttons and Cards */
.card-terraform {
  background-color: #f4e2d8;
}
.card-azure {
  background-color: #d1eaf2;
}
.card-docker {
  background-color: #e7daf6;
}
.card-kubernetes {
  background-color: #d1eaf2;
}
/* Add more color schemes as needed */
