/* TopicPage.css */

/* Container Styling */
.topic-page-container {
  padding: 100px 20px 20px; /* Account for the header */
  width: 100%; /* Ensure the container spans the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Grid Layout for Topic Cards */
.topic-page-container .card-grid {
  display: grid; /* Use grid for proper alignment */
  grid-template-columns: repeat(
    auto-fill,
    minmax(140px, 1fr)
  ); /* Dynamic multi-column layout */
  gap: 1rem; /* Adds spacing between cards */
  width: 100%; /* Make grid span the full width */
  max-width: 1200px; /* Optional: Limit width for aesthetics */
  justify-content: center; /* Center align the grid */
}

/* Card Styling */
.topic-page-container .card {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 140px; /* Slightly larger cards */
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.topic-page-container .card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.topic-page-container .card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.topic-page-container .card:hover .card-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  /* Tablets and small screens */
  .topic-page-container .card-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); /* Adjust grid layout for smaller screens */
  }

  .topic-page-container .card {
    height: 180px; /* Adjust card height for tablets */
  }

  .topic-page-container .card-overlay {
    font-size: 0.95rem; /* Slightly smaller text */
    padding: 0.8rem; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  /* Medium to Small Mobile Screens */
  .topic-page-container .card-grid {
    grid-template-columns: 1fr; /* Stack cards in a single column */
    gap: 0.5rem; /* Reduce gap */
  }

  .topic-page-container .card {
    height: 150px; /* Smaller cards for smaller screens */
    width: 150px;
  }

  .topic-page-container .card-overlay {
    font-size: 0.9rem; /* Adjust font size for readability */
    padding: 0.5rem; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  /* Very Small Mobile Screens */
  .topic-page-container .card {
    height: 130px; /* Even smaller cards */
    width: 130px;
  }

  .topic-page-container .card-overlay {
    font-size: 0.85rem; /* Even smaller font */
    padding: 0.4rem; /* Tighter padding */
  }

  /* Ensure the header spacing looks good */
  .topic-page-container {
    padding: 80px 10px 20px; /* Adjust the padding for the smaller screen */
  }
}

/* Close Button Styling */
.close-button {
  position: absolute; /* Positioned relative to the container */
  top: 20px; /* Below the header */
  right: 20px; /* Adjust to keep away from edges */
  background-color: #6c63ff; /* Aesthetic pastel color */
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.close-button:hover {
  background-color: #4b4fc0;
  transform: scale(1.1);
}

/* Responsive Adjustments for Close Button */
@media (max-width: 1024px) {
  .close-button {
    top: 15px; /* Adjust for tablets */
    right: 15px;
    width: 35px;
    height: 35px;
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .close-button {
    top: 10px; /* Adjust for smaller screens */
    right: 10px; /* Ensure it remains accessible */
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  /* Very Small Mobile Screens */
  .close-button {
    top: 5px; /* Slightly smaller top position */
    right: 5px;
    width: 30px; /* Smaller button size */
    height: 30px;
    font-size: 1rem; /* Adjust font size */
  }
}
